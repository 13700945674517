<template>
  <v-app id="inspire">
    <div class="screenLoader" v-if="isAppLoading">
      <div>
        <atom-spinner
          :animation-duration="1000"
          :size="60"
          :color="'#ff1d5e'"
        />
      </div>
      <div>... LOADING ...</div>
    </div>
    <router-view v-if="!isAppLoading"></router-view>
  </v-app>
</template>

<style>
@font-face {
  font-family: "EB Garamond";
  src: local("EB Garamond"), url("./assets/fonts/EBGaramond-Regular.ttf");
}

@font-face {
  font-family: "Caveat";
  src: local("Caveat"), url("./assets/fonts/Caveat-Regular.ttf");
}

@font-face {
  font-family: "Junge";
  src: local("Junge"), url("./assets/fonts/Junge-Regular.ttf");
}

@font-face {
  font-family: "Cinzel";
  src: local("Cinzel"), url("./assets/fonts/Cinzel-Regular.ttf");
}

@font-face {
  font-family: "Handlee";
  src: local("Handlee"), url("./assets/fonts/Handlee-Regular.ttf");
}

@font-face {
  font-family: "Gabriela";
  src: local("Gabriela"), url("./assets/fonts/Gabriela-Regular.ttf");
}

@font-face {
  font-family: "Cormorant";
  src: local("Cormorant"), url("./assets/fonts/Cormorant-Regular.ttf");
}

@font-face {
  font-family: "Roboto Condensed";
  src: local("Roboto Condense"),
    url("./assets/fonts/RobotoCondensed-Regular.ttf");
}

@font-face {
  font-family: "Englebert";
  src: local("Englebert"), url("./assets/fonts/Englebert-Regular.ttf");
}

@font-face {
  font-family: "Philosopher";
  src: local("Philosopher"), url("./assets/fonts/Philosopher-Regular.ttf");
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway"), url("./assets/fonts/Raleway-Regular.ttf");
}

@font-face {
  font-family: "Dancing Script";
  src: local("Dancing Script"), url("./assets/fonts/DancingScript-Regular.ttf");
}
@font-face {
  font-family: "Birthstone";
  src: local("Birthstone"), url("./assets/fonts/Birthstone-Regular.ttf");
}
@font-face {
  font-family: "Sacramento";
  src: local("Sacramento"), url("./assets/fonts/Sacramento-Regular.ttf");
}
@font-face {
  font-family: "Comforter";
  src: local("Comforter"), url("./assets/fonts/Comforter-Regular.ttf");
}
@font-face {
  font-family: "Euphoria Script";
  src: local("Euphoria Script"),
    url("./assets/fonts/EuphoriaScript-Regular.ttf");
}
@font-face {
  font-family: "Meow Script";
  src: local("Meow Script"), url("./assets/fonts/MeowScript-Regular.ttf");
}
@font-face {
  font-family: "Ruthie";
  src: local("Ruthie"), url("./assets/fonts/Ruthie-Regular.ttf");
}
@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
}
.top-menu {
  background-color: transparent !important;
}
.theme--dark.v-btn--active:hover::before,
.theme--dark.v-btn--active::before {
  background-color: transparent !important;
  opacity: 1 !important;
}
</style>

<script>
import store from "./store";
import { mapGetters } from "vuex";
import { AtomSpinner } from "epic-spinners";

export default {
  components: { "atom-spinner": AtomSpinner },
  store,
  computed: {
    ...mapGetters({
      isAppLoading: "isAppLoading",
    }),
  },
  name: "App",
};
</script>
<style lang="less" scoped>
.screenLoader {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
