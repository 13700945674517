import gql from "graphql-tag";

export const Q_PROGRAM_SUBSCRIBERS = gql`
  query program($programId: Float!) {
    program(programId: $programId) {
      subscriptions {
        id
        createdAt
        shares
        status
        lastSentDate
        subscriber {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;