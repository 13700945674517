import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "vuetify/dist/vuetify.min.css";
import "./index.css";
import VueClipboard from "vue-clipboard2";
import { createProvider } from "./vue-apollo";
import { library } from "@fortawesome/fontawesome-svg-core";
import VueCompositionAPI from '@vue/composition-api'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCoffee,
  faICursor,
  faSave,
  faSort,
  faComments,
  faMobile,
  faPhotoVideo,
  faFilePdf,
  faGlobe
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedin,
  faPinterest,
  faVimeo,
  faYoutube,


} from "@fortawesome/free-brands-svg-icons";

library.add(
  faICursor,
  faSave,
  faSort,
  faCoffee,
  faGlobe,
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedin,
  faPinterest,
  faVimeo,
  faYoutube,
  faPhotoVideo,
  faFilePdf,
  faComments,
  faMobile
);

Vue.config.productionTip = false;
Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueClipboard);


Vue.use(VueCompositionAPI)

new Vue({
  vuetify,
  store,
  apolloProvider: createProvider(),
  render: (h) => h(App),

  router,
}).$mount("#app");
