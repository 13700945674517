import { apolloClient } from "../../vue-apollo";
import gql from "graphql-tag";

import { Q_PROGRAM_SUBSCRIBERS } from "./graphql";

export default {
  state: {
    subscribers: [],
  },
  getters: {
    getSubscribers: (state) => state.subscribers,
  },
  mutations: {
    setSubscribers: (state, payload) => {
      state.subscribers = payload;
    },
  },
  actions: {
    async axDeleteSubscription(_ctx, { subId }) {
      await apolloClient.mutate({
        variables: {
          id: subId,
        },
        mutation: gql`
          mutation deleteSubscription($id: String!) {
            deleteSubscription(id: $id) {
              id
            }
          }
        `,
      });
    },
    async updateSubscriptionStatus(_ctx, { subId, status }) {
      await apolloClient
        .mutate({
          variables: {
            id: parseInt(subId) || -1,
            status: status,
          },
          mutation: gql`
            mutation updateSubscription($id: Float!, $status: String) {
              updateSubscription(id: $id, data: { status: $status }) {
                status
              }
            }
          `,
        })
        .catch((error) => console.log(error));
    },
    getSubscribers: async ({ commit }, programId) => {
      const response = await apolloClient
        .query({
          query: Q_PROGRAM_SUBSCRIBERS,
          fetchPolicy: "network-only",
          variables: {
            programId: parseInt(programId) || -1,
          },
        })
        .catch((error) => console.log(error));

      if (response && response.data) {
        commit(
          "setSubscribers",
          (response.data.program?.subscriptions || []).map((item) => ({
            ...item,
            // ...item.subscriber,
          }))
        );
      }
    },
  },
};
