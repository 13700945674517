import { apolloClient } from "../vue-apollo";
import gql from "graphql-tag";

export default {
  getters: {
    loggedInCoach: (state) => {
      return state.loggedInCoach;
    },
    getCurrentCoach: (state) => state.loggedInCoach, //Here Twice because the other name is bad and didn't feel like updating all the code.
    isAppLoading: (state) => {
      return state.isAppLoading;
    },
    getCoachBranding: (state) => state.coachBranding,
  },
  state: {
    loggedInCoach: null,
    isAppLoading: true,
    coachBranding: {},
  },
  mutations: {
    setIsAppLoading: function setAppLoading(state, payload) {
      state.isAppLoading = payload;
    },
    loginCoach: function loginCoach(state, payload) {
      localStorage.setItem("loggedInCoach", JSON.stringify(payload));
      state.loggedInCoach = payload;
    },
    isLoggedIn: function isLoggedIn(state, payload) {
      state.loggedInCoach = payload;
    },
    setCoach: function isLoggedIn(state, payload) {
      state.loggedInCoach = payload;
    },
    updateCoachData: function(state, payload) {
      const currentCoach = state.loggedInCoach;
      state.loggedInCoach = {
        ...currentCoach,
        ...payload,
      };
    },
    setCoachBranding: function(state, payload) {
      state.coachBranding = payload;
    },
  },
  actions: {
    async axCoachBranding({ commit }, { id }) {
      const response = await apolloClient.query({
        query: gql`
          query CoachBranding($id: Float!) {
            CoachBranding(id: $id) {
              id
              welcomeMessage
              contactInfoName
              contactInfoHeader
              contactInfoPhoto
              contactInfoIntroduction
              contactInfoEmail
              contactInfoTwitter
              contactInfoYoutube
              contactInfoFacebook
              contactInfoInstagram
              contactInfoLinkedIn
              nameInMainMenu
            }
          }
        `,
        fetchPolicy: "network-only",
        variables: {
          id: parseInt(id),
        },
      });

      if (response && response.data) {
        commit("setCoachBranding", response.data.CoachBranding);
      }
    },

    async checkIfLoggedIn({ commit }, coachData) {
      commit("isLoggedIn", coachData);
    },
    async getCoachData({ commit, state }) {
      const id = state.loggedInCoach.id;
      const response = await apolloClient
        .query({
          query: gql`
            query coach($id: String!) {
              coach(id: $id) {
                id
                firstName
                lastName
                companyName
                email
                status
                mobile
                password
                planLevel
                numPrograms
                numSubscribers
                numContentItems
                planMaxPrograms
                planMaxContentItems
                planSubscribers
                coachLogo
                coachLinks {
                  id
                  url
                  label
                  linkType
                }
              }
            }
          `,
          fetchPolicy: "network-only",
          variables: {
            id,
          },
        })
        .catch((error) => console.log(error));

      if (response && response.data) {
        commit("setCoach", response.data.coach);
      }
    },
    async axCoachLoginFromStorage({ commit }) {
      const userToken = window.localStorage.getItem("userToken");
      if (!userToken) {
        commit("setIsAppLoading", false);
        return;
      }

      const response = await apolloClient
        .query({
          query: gql`
            query coach($id: String!) {
              coach(id: $id) {
                id
                firstName
                lastName
                companyName
                email
                status
                mobile
                password
                planLevel
                numPrograms
                numSubscribers
                numContentItems
                planMaxPrograms
                planMaxContentItems
                planSubscribers
                coachLogo
                coachLinks {
                  id
                  url
                  label
                  linkType
                }
              }
            }
          `,
          fetchPolicy: "network-only",
          variables: {
            id: userToken,
          },
        })
        .catch((error) => console.log(error));

      if (response && response.data) {
        // console.log(response.data.coach)
        commit("setCoach", response.data.coach);
        commit("setIsAppLoading", false);
      }
    },
    async uploadCoachHeadShot(_ctx, { coachId, headShotBase64 }) {
      
      await apolloClient.mutate({
        variables: {
          id: parseInt(coachId),
          data: {
            contactInfoPhoto: headShotBase64,
          },
        },
        mutation: gql`
          mutation updateCoachBrandingContactInfo(
            $id: Float!
            $data: CoachBrandingContactInput!
          ) {
            updateCoachBrandingContactInfo(id: $id, data: $data) {
              id
            }
          }
        `,
      });

      _ctx.dispatch("axCoachBranding", {id: coachId})
    },
    async axUpdateCoachLogo(_ctx, {coachId, base64Logo}) {
      
      const request = await apolloClient.mutate({
        variables: {
          coachId: parseInt(coachId),
          data: {
            coachLogo: base64Logo
          }
        },
        mutation: gql`
        
        mutation updateCoachLogo(
            $coachId: Float!
            $data: CoachLogoInput!
          ) {
            updateCoachLogo(coachId: $coachId, data: $data) {
              id
            }
          }
        `
      }).catch(error => console.log(error))

      if (request && request.data) {
        _ctx.dispatch("getCoachData")
      }
    },
    async attemptLogin({ commit }, { email, password }) {
      const { data } = await apolloClient.query({
        fetchPolicy: "network-only",
        query: gql`
          query coachLogin($password: String!, $email: String!) {
            coachLogin(password: $password, email: $email) {
              id
              firstName
              lastName
              companyName
              email
              status
              mobile
              password
              numPrograms
              numSubscribers
              numContentItems
              planMaxPrograms
              planMaxContentItems
              planSubscribers
              startDate
              createdAt
              updatedAt
              coachLogo
              coachLinks {
                id
                url
                linkType
                label
              }
            }
          }
        `,
        variables: {
          email: email,
          password: password,
        },
      });
      if (data && data.coachLogin?.id) {
        window.localStorage.setItem("userToken", data.coachLogin.id);
        commit("loginCoach", data.coachLogin);
        return true;
      }
    },
    async addCoachLink({ dispatch }, { coachId, label, url, linkType }) {
      const response = await apolloClient
        .mutate({
          variables: {
            data: {
              coachId,
              label,
              url,
              linkType,
            },
          },
          mutation: gql`
            mutation addCoachLink($data: CoachLinkInput!) {
              addCoachLink(data: $data) {
                id
                url
                label
                linkType
              }
            }
          `,
        })
        .catch((error) => console.log(error));

      if (response && response.data) {
        dispatch("getCoachData");
      }
    },
    async deleteCoachLink({ dispatch }, { id }) {
      const response = await apolloClient
        .mutate({
          variables: {
            id: parseInt(id),
          },
          mutation: gql`
            mutation removeCoachLink($id: Float!) {
              deleteCoachLink(id: $id) {
                id
                url
                label
                linkType
              }
            }
          `,
        })
        .catch((error) => console.log(error));

      if (response && response.data) {
        dispatch("getCoachData");
      }
    },
    async secretLogin({ commit }, id) {
      const response = await apolloClient
        .query({
          query: gql`
            query coach($id: String!) {
              coach(id: $id) {
                id
                firstName
                lastName
                companyName
                email
                status
                mobile
                password
                numPrograms
                numSubscribers
                numContentItems
                planMaxPrograms
                planMaxContentItems
                planSubscribers
                coachLogo
                coachLinks {
                  id
                  url
                  label
                  linkType
                }
              }
            }
          `,
          fetchPolicy: "network-only",
          variables: {
            id,
          },
        })
        .catch((error) => console.log(error));

      if (response && response.data) {
        window.localStorage.setItem("userToken", response.data.coach.id);
        commit("loginCoach", response.data.coach);
        return true;
      }

      return false;
    },
  },
};
