import { apolloClient } from "../vue-apollo";
import gql from "graphql-tag";

export default {
  getters: {
    getCurrentProgram: (state) => state.currentProgram,
    getProgramItems: (state) => state.programItems,
    getCurrentItem: (state) => state.currentProgramItem,
    fonts: (state) => state.fontFamilies,
    fontSizes: (state) => state.fontSizes,
    getCurrentProgramItem: (state) => state.currentProgramItem,
    getPrograms: (state) => state.coachPrograms,
    coachColors: (state) => state.coachColors,
  },
  state: {
    currentProgramItem: {},
    currentProgram: null,
    coachPrograms: [],
    coachColors: [],
    programItems: [],
    fontFamilies: [
      {
        displayName: "Birthstone",
        fontName: "Birthstone",
      },
      {
        displayName: "EB Garamond",
        fontName: "EB Garamond",
      },
      {
        displayName: "Junge",
        fontName: "Junge",
      },
      {
        displayName: "Cinzel",
        fontName: "Cinzel",
      },
      {
        displayName: "Gabriela",
        fontName: "Gabriela",
      },
      {
        displayName: "Cormorant",
        fontName: "Cormorant",
      },
      {
        displayName: "Raleway",
        fontName: "Raleway",
      },
      {
        displayName: "Roboto Condensed",
        fontName: "Roboto Condensed",
      },
      {
        displayName: "Englebert",
        fontName: "Englebert",
      },
      {
        displayName: "Philosopher",
        fontName: "Philosopher",
      },
      {
        displayName: "Raleway Regular",
        fontName: "Raleway-Regular",
      },
      {
        displayName: "Roboto Regular",
        fontName: "Roboto-Regular",
      },
      {
        displayName: "Dancing Script",
        fontName: "Dancing Script",
      },
      {
        displayName: "Sacramento",
        fontName: "Sacramento",
      },
      {
        displayName: "Comforter",
        fontName: "Comforter",
      },
      {
        displayName: "Caveat",
        fontName: "Caveat",
      },
      {
        displayName: "Comforter",
        fontName: "Comforter",
      },
      {
        displayName: "Handlee",
        fontName: "Handlee",
      },
      {
        displayName: "Licorice",
        fontName: "Licorice",
      },
      {
        displayName: "Euphoria Script",
        fontName: "Euphoria Script",
      },
      {
        displayName: "Meow Script",
        fontName: "Meow Script",
      },
      {
        displayName: "Ruthie",
        fontName: "Ruthie",
      },
    ],
    fontSizes: [
      {
        sizeName: "Extra Small",
        fontSize: "1.2rem",
      },
      {
        sizeName: "Small",
        fontSize: "1.4rem",
      },
      {
        sizeName: "Medium",
        fontSize: "1.5rem",
      },
      {
        sizeName: "Large",
        fontSize: "1.9rem",
      },
      {
        sizeName: "Extra Large",
        fontSize: "2.2rem",
      },
    ],
  },
  mutations: {
    setCoachPrograms: function setCoachPrograms(state, payload) {
      state.coachPrograms = payload;
    },
    setCoachColors: function setCoachColors(state, payload) {
      state.coachColors = payload;
    },
    setProgramItems: function setProgramItems(state, payload) {
      state.programItems = payload;
    },
    setCurrentProgramItem: function setCurrentProgramItem(state, payload) {
      state.currentProgramItem = payload;
    },
    setCurrentProgram: function loginCoach(state, payload) {
      state.currentProgram = payload;
    },
    resetCurrentProgramItem: function resetProgramItem(state) {
      state.currentProgramItem = {};
    },
  },
  actions: {
    async updateProgramContentItem(
      _params,
      { programId, text, graphic, order, ...args }
    ) {
      const id = parseInt(args.id);
      delete args.id;
      delete args.program;

      const { data } = await apolloClient.mutate({
        variables: {
          id,
          data: {
            programId: programId,
            graphic,
            order,
            text,
            ...args,
          },
        },
        mutation: gql`
          mutation updateProgramItem($id: Float!, $data: ProgramItemInput!) {
            updateProgramItem(id: $id, data: $data) {
              id
            }
          }
        `,
      });
      if (data) {
        // await this.getProgramContentItems({commit}, {programId})
      }
    },
    async addProgramContentItem(
      { dispatch, commit },
      { programId, text, graphic, order, contentType, ...args }
    ) {
      commit("resetCurrentProgramItem");
      const { data } = await apolloClient.mutate({
        variables: {
          data: {
            programId,
            order,
            text,
            graphic,
            contentType,
            ...args,
          },
        },
        mutation: gql`
          mutation createProgramItem($data: ProgramItemInput!) {
            createProgramItem(data: $data) {
              id
            }
          }
        `,
      });
      if (data) {
        await dispatch("getProgramContentItems", { programId });
        return data;
      }
    },
    async getProgramContentItems({ commit }, { programId }) {
      const { data } = await apolloClient.query({
        variables: {
          programId: parseInt(programId),
        },
        query: gql`
          query fetchProgramContent($programId: Float!) {
            programItems(programId: $programId) {
              id
              name
              order
              text
              graphic
              fontSize
              fontFamily
              contentType
              showLogo
              numShares
              status
            }
          }
        `,
        fetchPolicy: "network-only",
      });
      commit("setProgramItems", data.programItems);
    },
    async axProgramItem({ commit }, { programItemId }) {
      commit("resetCurrentProgramItem");

      const { data } = await apolloClient.query({
        variables: {
          programItemId: parseInt(programItemId),
        },
        query: gql`
          query fetchOneProgramContent($programItemId: Float!) {
            programItem(programItemId: $programItemId) {
              id
              order
              name
              text
              graphic
              showLogo
              fontSize
              fontColor
              fontFamily
              textPositionY
              textPositionX
              logoUrl
              logoPositionY
              logoPositionX
              backgroundColor
              contentType
              program {
                id
                name
              }
            }
          }
        `,
      
        fetchPolicy: "network-only",
      });
      commit("setCurrentProgramItem", data.programItem);
    },

    async axProgram({ commit }, { program }) {
      const programId = parseInt(program.id);
      //Let's get the full Program from Graphql
      const { data } = await apolloClient.query({
        variables: {
          programId: parseInt(programId),
        },
        query: gql`
          query program($programId: Float!) {
            program(programId: $programId) {
              id
              name
              status
              coach {
                id
                firstName
                lastName
              }
              frequency
              frequencyDetail
              frequencyTime
              frequencyTimeZone
              timezone
              fSunday
              fMonday
              fTuesday
              fWednesday
              fThursday
              fFriday
              fSaturday
              moreUrl
            }
          }
        `,
        fetchPolicy: "no-cache",
      });

      commit("setCurrentProgram", data.program);
    },
    async deleteProgramContentItem({ dispatch }, { programItemId, programId }) {
      const { data } = await apolloClient.mutate({
        variables: {
          id: programItemId,
        },
        mutation: gql`
          mutation deleteProgramItem($id: String!) {
            deleteProgramItem(id: $id) {
              id
            }
          }
        `,
      });
      if (data) {
        await dispatch("getProgramContentItems", { programId });
      }
    },
    async hideProgramContentItem({ dispatch }, { programItemId, programId }) {
      const id = parseInt(programItemId)
      const { data } = await apolloClient.mutate({
        variables: {
          id,
          status: 'hidden'
        },
        mutation: gql`
          mutation hideProgramItem($id: Float!, $status: String!) {
            hideProgramItem(id: $id, status: $status) {
              id
            }
          }
        `,
      });
      if (data) {
        await dispatch("getProgramContentItems", { programId });
      }
    },
    async unhideProgramContentItem({ dispatch }, { programItemId, programId }) {
      const id = parseInt(programItemId)
      const { data } = await apolloClient.mutate({
        variables: {
          id,
          status: 'active'
        },
        mutation: gql`
          mutation hideProgramItem($id: Float!, $status: String!) {
            hideProgramItem(id: $id, status: $status) {
              id
            }
          }
        `,
      });
      if (data) {
        await dispatch("getProgramContentItems", { programId });
      }
    },
    async deleteProgram({ dispatch }, { programId, coachId }) {
      //Confirm Deletion
      // const confirm = window.confirm(
      //   "Are you sure you want to delete this program?"
      // );
      // if (!confirm) {
      //   return false;
      // }
      const { data } = await apolloClient.mutate({
        variables: {
          id: programId,
        },
        mutation: gql`
          mutation deleteProgram($id: String!) {
            deleteProgram(id: $id) {
              id
            }
          }
        `,
      });
      if (data) {
        await dispatch("getCoachPrograms", { coachId });
        await dispatch("getCoachData");
      }
    },
    async getCoachPrograms({ commit }, { coachId }) {
      const { data } = await apolloClient.query({
        fetchPolicy: "network-only",
        variables: {
          id: coachId,
        },
        query: gql`
          query getCoachPrograms($id: String!) {
            coach(id: $id) {
              id
              status
              firstName
              lastName
              email
              mobile
              planLevel
              numPrograms
              numSubscribers
              numContentItems
              programs {
                id
                name
                code
                status
                numSubscribers
                numContentItems
              }
            }
          }
        `,
      });
      if (data) {
        await commit("setCoachPrograms", data.coach.programs);
        await commit("getCoachData");
      }
    },
    async getCoachColors({ commit }, { coachId }) {
      const { data } = await apolloClient.query({
        fetchPolicy: "network-only",
        variables: {
          coachId: coachId * 1,
        },
        query: gql`
          query coachColors($coachId: Float!) {
            coachColors(coachId: $coachId) {
              id
              hex
            }
          }
        `,
      });
      if (data) {
        await commit("setCoachColors", data.coachColors);
      }
    },
    async switchProgramContentItems({ dispatch }, { programId, fItem, sItem }) {
      await dispatch("updateProgramContentItem", {
        programId,
        id: fItem.id,
        order: sItem.order,
      });
      await dispatch("updateProgramContentItem", {
        programId,
        id: sItem.id,
        order: fItem.order,
      });
      dispatch("getProgramContentItems", { programId });
    },
    async axResetCurentProgram({ commit }) {
      commit("resetCurrentProgramItem");
    },
  },
};
