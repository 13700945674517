import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import program from "./program";
import media from "./media";
import subscriber from "./subscriber";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    program,
    media,
    subscriber,
  },
});
export default store;
