import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard/Dashboard.vue";
import store from "@/store"; // <-- aliased path

Vue.use(VueRouter);

const routes = [
  {
    path: "/app",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        component: () => import("../views/Programs.vue"),
      },
      {
        path: "programs/:programId",
        component: () => import("../views/CuratedContent.vue"),
      },
      {
        path: "programs/:programId/subscribers",
        component: () => import("../views/ClientPage.vue"),
      },
      {
        path: "programs/:programId/content/:contentId/edit",
        component: () => import("../views/GraphicEditor.vue"),
      },
      {
        path: "programs/:programId/add/:nextItemIndex",
        component: () => import("../views/GraphicEditor.vue"),
      },
      {
        path: "profile",
        component: () => import("../views/CoachProfile.vue"),
      },
      {
        path: "library",
        component: () => import("../views/BackgroundLibrary.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/app",
  },

  // {
  //   path: '/subscribers',
  //   name: 'Subscribers',

  //   component: () => import('../views/Subscribers/Subscribers.vue')
  // },
  // {
  //   path: '/program-detail',
  //   name: 'ProgramDetail',

  //   component: () => import('../views/ProgramDetail/ProgramDetail.vue')
  // },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/masterbackdoorsecret",
    name: "Masterbackdoorsecret",
    component: () =>
      import(
        /* webpackChunkName: "backdoor" */ "../views/MasterBackdoorSecret.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(async (to, from, next) => {
  const publicPages = ["/login", "/masterbackdoorsecret"];
  const authRequired = !publicPages.includes(to.path);
  const viaSecret = to.path == "/masterbackdoorsecret" ? true : false;
  const isAppLoading = store.getters["isAppLoading"];

  if (isAppLoading && !viaSecret) {
    await store.dispatch("axCoachLoginFromStorage");
  }
  if (viaSecret) {
    const { key } = to.query;
    if (key && key.startsWith("thisisnotforeveryone_")) {
      const id = key.replace("thisisnotforeveryone_", "");
      if (await store.dispatch("secretLogin", id)) next("/");
      else next("/login");
    } else next("/login");
  }

  const loggedIn = store.getters["loggedInCoach"];
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});
export default router;
